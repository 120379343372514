/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Custom SCSS */
@import "theme/fd-fonts.scss";
@import "theme/fd-palette.scss";
@import "theme/fd-layout.scss";
@import "theme/fonts.scss";
@import "theme/palette.scss";
@import "theme/variables.scss";
@import "theme/fd-status.scss";
@import "theme/fd-mixins.scss";
@import "theme/ion-customized.scss";

.logo {
    width: 170px;
    margin: 0 auto;
}

.alert-wrapper {
    .alert-button {
        color: var(--fdr-color_secondary-900);
    }
}

.rebate {
    &__item-small {
        font-size: var(--fdr-font-size_smallest);
        font-weight: 500;
        color: var(--fdr-color-font_dark_4);
        text-decoration: line-through;
        opacity: 0.7;
        width: 100%;
    }
}

.picker-button.sc-ion-picker-ios {
    color: var(--fdr-color_secondary-900);
}

.fd2-card {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px var(--fd-shadow);
    background-color: var(--fd-body__background-detail);
}
