.fd {
    --fd-font-size_structure: 16px;
}

.fd-font-caption {
    font-size: var(--fdr-font-size_smallest);
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.4px;
}

.fd-t-heading_color_primary_1 {
    color: var(--fdr-color_secondary-900);
    font-size: var(--fdr-font-size_medium);
    font-weight: 600;
    line-height: 32px;
}

.fd-t-overline {
    color: var(--fdr-color-font_dark_2);
    font-weight: 500;
    font-size: var(--fdr-font-size_smallest);
    line-height: 16px;
}

.fd-t-body_color_dark_2 {
    color: var(--fdr-color-font_dark_3);
    font-weight: 500;
    font-size: var(--fdr-font-size_smallest);
    line-height: 16px;
}

.fd-t-body_color_dark_3 {
    color: var(--fdr-color-font_dark_4);
    font-weight: 500;
    font-size: var(--fdr-font-size_smallest);
    line-height: 16px;
}

.fd-t-body_bold_color_dark_3 {
    color: var(--fdr-color-font_dark_4);
    font-weight: 600;
    font-size: var(--fdr-font-size_smallest);
    line-height: 16px;
}

.fd-font-h6 {
    color: var(--fdr-color-font_dark_4);
    font-size: var(--fdr-font-size_small-to-medium);
    font-weight: 600;
    line-height: 24px;
}

.fd-line-through {
    color: var(--fdr-color-font_dark_3);
    font-weight: 600;
    font-size: var(--fdr-font-size_smallest);
    line-height: 16px;
    text-decoration: line-through;
}
