.kutxa {
    --fdr-color_secondary-900: #a50050;
    --fdr-color_secondary-dark-900: #77003b;
    --fd-color_dark: #000000;
    --fdr-color-font_dark_1: #cccccc;
    --fdr-color-font_dark_2: #999999;
    --fdr-color-font_dark_3: #666666;
    --fdr-color-font_dark_4: #333333;
    --fd-t-heading_color_primary_1: #51284f;
    --fd-body__background-main: #f7f6f7;
    --fd-body__background-detail: #ffffff;
    --fd-link: #84bd00;
    --fd-shadow: #0015290a;
}
