@mixin status-color {
    &_info {
        background-color: var(--fd-bg-color_info);
    }

    &_complete {
        color: var(--fdr-color_primary-700);
    }

    &_default {
        background-color: var(--fd-body__background-main);
    }

    &_success {
        background-color: var(--fd-color_success-100);
    }

    &_success-100 {
        color: var(--fd-color_success-100);
    }

    &_success-700 {
        color: var(--fd-color_success-700);
    }

    &_success-semi-bold {
        color: var(--fd-color_success-semi-bold);
    }

    &_danger {
        background-color: var(--fd-bg-color_danger);
    }

    &_warning {
        background-color: var(--fd-bg-color_warning);
    }

    &_warning-700 {
        color: var(--fd-color_warning-700);
    }

    .fd2-color {
        @extend .fd2-status-color;

        &_tertiary_100 {
            color: var(--fdr-color_tertiary-100);
        }

        &_tertiary_200 {
            color: var(--fdr-color_tertiary-200);
        }

        &_primary {
            color: var(--fd-color_primary);
        }

        &_tertiary_500 {
            color: var(--fdr-color_tertiary-500);
        }

        &_primary-500 {
            color: var(--fdr-color_primary-500);
        }

        &_primary-700 {
            color: var(--fdr-color_primary-700);
        }

        &_black-opacity-middle {
            color: var(--fdr-color_black_opacity_middle);
        }
    }
}
