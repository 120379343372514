@import "fd-mixins.scss";

.fd {
    /* primary */
    --fd-color_primary: #2674bc;
    --fd-color_primary-200: #8dc9f4;
    --fdr-color_primary-500: #0095eb;
    --fdr-color_primary-700: #0075cb;

    /* secondary */
    --fdr-color_secondary-700: #600581;

    /* tertiary */
    --fdr-color_tertiary-100: #d9d8db;
    --fdr-color_tertiary-200: #bbb9be;
    --fdr-color_tertiary-400: #807c85;
    --fdr-color_tertiary-500: #636067;
    --fdr-color_tertiary-800: #111112;

    /* others */
    --fdr-color_black: #000000;
    --fdr-color_black_opacity_dark: rgba(0, 0, 0, 0.8);
    --fdr-color_black_opacity_middle: rgba(0, 0, 0, 0.6);
    --fdr-color_tertiary-100_opacity: #d9d8db99;

    /* status color */
    --fd-bg-color_info: #e2f2fc;
    --fd-color_success: #5cb519;
    --fd-color_success-100: #ecfbcf;
    --fd-color_success-700: #31820c;
    --fd-color_success-semi-bold: #459b12;
    --fd-color_danger: #b7160e;
    --fd-color_danger_light: #fde9e7;
    --fd-bg-color_danger: #ff8e75;
    --fd-color_warning: #ffc300;
    --fd-color_warning-700: #b78300;
    --fd-bg-color_warning: #ffee99;
}

.fd2-status-color {
    @include status-color;
}
