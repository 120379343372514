.main{
  /* Font */
  --fdr-font-family_default: Montserrat;

  --fd-font-size_structure: 16px;

  /* Font size */
  --fdr-font-size_info: 10px;
  --fdr-font-size_badge: 11px;
  --fdr-font-size_smallest: 12px;
  --fdr-font-size_smaller: 14px;
  --fdr-font-size_small: 16px;
  --fdr-font-size_check: 18px;

  --fdr-font-size_small-to-medium: 20px;
  --fdr-font-size_medium: 24px;
  --fdr-font-size_medium-to-large: 34px;

  --fdr-font-size_large: 48px;
  --fdr-font-size_larger: 60px;
  --fdr-font-size_largest: 96px;

  /* Opacity */
  --fd-font-opacity_no: 1;
  --fd-font-opacity_dark: 0.8;
  --fd-font-opacity_medium: 0.6;
  --fd-font-opacity_light: 0.4;
  --fd-font-opacity_lightest: 0.2;
}
