.fd-status {
  font-size: var(--fd-font-size_structure);

  display: flex;
  align-items: center;
  vertical-align: middle;

  white-space: nowrap;

  opacity: 1 !important;

  &_state {
    font-size: var(--fdr-font-size_badge);
    font-weight: 400;
    line-height: 16px;

    &_info {
      color: var(--fd-color_primary);

      .fd-status__text {
        color: var(--fd-color_primary);
      }
    }

    &_complete {
      color: var(--fdr-color_primary-700);

      .fd-status__text {
        color: var(--fdr-color_primary-700);
      }
    }

    &_default {
      color: var(--fdr-color_tertiary-400);

      .fd-status__text {
        color: var(--fdr-color_tertiary-400);
      }
    }

    &_success {
      color: var(--fd-color_success);

      .fd-status__text {
        color: var(--fd-color_success);
      }
    }

    &_danger {
      color: var(--fd-color_danger);

      .fd-status__text {
        color: var(--fd-color_danger);
      }
    }

    &_warning {
      color: var(--fd-color_warning);

      .fd-status__text {
        color: var(--fd-color_warning);
      }
    }
  }
}
